<template>
  <v-menu
    ref="timeMenu"
    v-model="menuOpen"
    :close-on-content-click="false"
    :nudge-right="40"
    transition="scale-transition"
    offset-y
    max-width="290px"
    min-width="290px"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-text-field
        v-bind="attrs"
        v-on="on"
        v-model="innerVal"
        :label="label"
        prepend-icon="mdi-clock-time-four-outline"
        readonly
        append-outer-icon="mdi-refresh"
        @click:append-outer="refreshTimeValue()"
      ></v-text-field>
    </template>
    <v-time-picker
      v-if="menuOpen"
      v-model="innerVal"
      full-width
      format="24hr"
      @change="$refs.timeMenu.save(innerVal)"
    ></v-time-picker>
  </v-menu>
</template>

<script>
import { VMenu, VTextField, VTimePicker } from "vuetify/lib";

export default {
  name: "w-hour-minute",

  components: { VMenu, VTextField, VTimePicker },

  props: {
    label: { type: String, default: "" },
    val: { type: String, default: "" },
    def: { type: String, default: "" },
  },

  data: () => ({
    menuOpen: false,
  }),

  computed: {
    innerVal: {
      get: function () {
        return this.val;
      },

      set: function (val) {
        this.$emit("update:val", val);
      },
    },
  },

  methods: {
    refreshTimeValue() {
      this.innerVal = this.def;
    },
  },
};
</script>

<style scoped>
</style>
