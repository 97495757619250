<template>
  <v-menu
    ref="dateMenu"
    v-model="menuOpen"
    :close-on-content-click="false"
    transition="scale-transition"
    offset-y
    max-width="290px"
    min-width="auto"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-text-field
        v-model="formattedDate"
        :label="label"
        prepend-icon="mdi-calendar"
        readonly
        append-outer-icon="mdi-refresh"
        v-bind="attrs"
        v-on="on"
        @click:append-outer="refreshDateValue()"
      ></v-text-field>
    </template>
    <v-date-picker v-model="innerVal" no-title @input="$refs.dateMenu.save(innerVal)"></v-date-picker>
  </v-menu>
</template>

<script>
import dateformat from "../utils/dateformat";
import { VDatePicker, VMenu, VTextField } from "vuetify/lib";

export default {
  name: "w-date",

  components: { VDatePicker, VMenu, VTextField },

  props: {
    label: { type: String, default: "" },
    val: { type: String, default: "" },
  },

  data: () => ({
    menuOpen: false,
  }),

  computed: {
    innerVal: {
      get: function () {
        return this.val;
      },
      set: function (val) {
        this.$emit("update:val", val);
      },
    },

    formattedDate: {
      get: function () {
        return dateformat(this.innerVal);
      },
      set: function (val) {
        // never called
        console.log("calling setFormattedDate with val: ", val);
      },
    },
  },

  methods: {
    refreshDateValue() {
      this.innerVal = new Date().toJSON().slice(0, 10);
    },
  },
};
</script>

<style scoped>
</style>
