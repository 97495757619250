<template>
  <v-container :fluid="$vuetify.breakpoint.lgAndDown">
    <v-row no-gutters>
      <v-col>
        <!-- ---------------------------------------------------------------------------------------------------------- ->
        <! search inputs                                                                                                ->
        <!- ------------------------------------------------------------------------------------------------------------->
        <v-row justify="center" no-gutters>
          <v-form ref="form" @submit.prevent="search">
            <!-- ------- ->
            <! line 1    ->
            <!- ---------->
            <v-row no-gutters class="mt-4">
              <!-- ------- ->
              <! from day  ->
              <!- ---------->
              <v-col cols="auto">
                <w-date :val.sync="fromDay" label="from day" @update:val="search()" />
              </v-col>

              <!-- ------- ->
              <! from hour ->
              <!- ---------->
              <v-col cols="auto" class="mx-8">
                <w-hour-minute
                  :val.sync="fromHour"
                  label="from hour"
                  @update:val="search()"
                  def="06:00"
                />
              </v-col>

              <!-- -------------- ->
              <! client reference ->
              <!- ----------------->
              <v-col cols="auto">
                <v-text-field
                  label="client order ref."
                  placeholder="e.g.: 104220180000646"
                  v-model="clientReference"
                  clearable
                  outlined
                />
              </v-col>
            </v-row>

            <!-- ------- ->
            <! line 2    ->
            <!- ---------->
            <v-row no-gutters>
              <!-- ------- ->
              <! until day ->
              <!- ---------->
              <v-col cols="auto">
                <w-date :val.sync="untilDay" label="until day" @update:val="search()" />
              </v-col>

              <!-- -------- ->
              <! until hour ->
              <!- ----------->
              <v-col cols="auto" class="mx-8">
                <w-hour-minute
                  :val.sync="untilHour"
                  label="until hour"
                  @update:val="search()"
                  def="22:00"
                />
              </v-col>

              <!-- --------- ->
              <! receiver ID ->
              <!- ------------>
              <v-col cols="auto">
                <v-text-field
                  label="receiver"
                  placeholder="e.g.: 73, NOR1012, etc"
                  v-model="receiverID"
                  clearable
                  outlined
                />
              </v-col>
            </v-row>

            <!-- ------------ ->
            <!- search button ->
            <!- --------------->
            <v-row no-gutters>
              <v-col class="d-flex flex-row-reverse">
                <v-btn color="primary" type="submit">search</v-btn>
              </v-col>
            </v-row>
          </v-form>
        </v-row>

        <!-- ---------------------------------------------------------------------------------------------------------- ->
        <! search results                                                                                               ->
        <!- ------------------------------------------------------------------------------------------------------------->
        <v-row justify="center" class="ma-4">
          <v-col xs="12" sm="12" md="12">
            <v-card class="elevation-6">
              <w-entity-list
                :columns="columns"
                :items="orders"
                :read-path="readPath"
                read-ref="orderReference"
                title="Orders"
                sort-by="created,desc"
              />
            </v-card>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import {
  VBtn,
  VCard,
  VCol,
  VContainer,
  VForm,
  VRow,
  VTextField,
} from "vuetify/lib";
import WEntityList from "../../components/external/modules/shared/views/WEntityList.vue";
import WDate from "../../components/external/modules/shared/views/WDate.vue";
import WHourMinute from "../../components/external/modules/shared/views/WHourMinute.vue";
import api from "../../components/external/modules/shared/utils/api";
import path from "../../shared/paths";
import dateformat from "../../components/external/modules/shared/utils/dateformat";
import datehourformat from "../../components/external/modules/shared/utils/datehourformat";
import lsync from "../../components/external/modules/shared/utils/lsync";

// used to transform a date in the local timezone to a UTC date with format 'YYYY/MM/DD hh:mm'
const toUTC = (dateString) => {
  const [dayString, hourString] = new Date(dateString)
    .toISOString()
    .slice(0, 19)
    .split("T");
  return dateformat(dayString) + " " + hourString;
};

const field = Object.freeze({
  FROMDAY: "Orders.fromDay",
  FROMHOUR: "Orders.fromHour",
  UNTILDAY: "Orders.untilDay",
  UNTILHOUR: "Orders.untilHour",
  CLIENTREF: "Orders.clientRef",
  RECEIVER: "Orders.receiver",
});

export default {
  components: {
    VBtn,
    VCard,
    VCol,
    VContainer,
    VForm,
    VRow,
    VTextField,
    WDate,
    WEntityList,
    WHourMinute,
  },

  data: () => ({
    fromDay: lsync(field.FROMDAY) || new Date().toJSON().slice(0, 10),
    fromHour: lsync(field.FROMHOUR) || "06:00",
    untilDay: lsync(field.UNTILDAY) || new Date().toJSON().slice(0, 10),
    untilHour: lsync(field.UNTILHOUR) || "22:00",
    clientReference: lsync(field.CLIENTREF) || "",
    receiverID: lsync(field.RECEIVER) || "",
    orders: [],
    columns: [
      { value: "_createdBy", text: "client" },
      { value: "orderReference", text: "hamp ref." },
      { value: "created", text: "created" },
      { value: "orderClientReference", text: "client ref." },
      { value: "nbItems", text: "nb. items" },
      { value: "orderStatusString", text: "status" },
      { value: "receiverID", text: "receiver" },
    ],
    readPath: path.ORDER,
  }),

  watch: {
    clientReference(newValue) {
      // detecting the clearing of the field
      if (newValue === null) {
        this.clientReference = lsync(field.CLIENTREF, "");
        this.search();
      }
    },

    receiverID(newValue) {
      // detecting the clearing of the field
      if (newValue === null) {
        this.receiverID = lsync(field.RECEIVER, "");
        this.search();
      }
    },
  },

  created() {
    this.search();
  },

  methods: {
    search() {
      const portfolio = {};
      portfolio.fromTimeString = toUTC(
        dateformat(lsync(field.FROMDAY, this.fromDay)) +
          " " +
          lsync(field.FROMHOUR, this.fromHour)
      );
      portfolio.untilTimeString = toUTC(
        dateformat(lsync(field.UNTILDAY, this.untilDay)) +
          " " +
          lsync(field.UNTILHOUR, this.untilHour)
      );
      portfolio.orderClientReference = lsync(
        field.CLIENTREF,
        this.clientReference
      );
      portfolio.receiverID = lsync(field.RECEIVER, this.receiverID);

      this.orders = []; // cleaning the orders

      // performing the search
      api
        .post("portfolio/read", portfolio)
        .then((res) => {
          if (res.data.status === 200 && res.data.entity !== null) {
            this.orders = res.data.entity.orders.map((order) => {
              // formatting the creation date - NB: this should probably be put into a reusable function
              order.created = datehourformat(order._creation);
              return order;
            });
          } else {
            console.log(new Error("Failed searching the orders"));
            console.log(res);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },
};
</script>

<style>
</style>
